import React, { Component } from 'react';

import Collections from '../body/Collections';
import Contact from '../body/Contact';
import SubTitle from '../body/SubTitle';

class Color extends Component {
  render() {
    const serviceData = {
      imgurl: '/images/services/color.jpg',
      smallTitle: 'Hair by Julie',
      title: 'Color',
      data: [
        {
        id: '0',
        title: "Color",
        price: '120',
        details: "Using either permanent or semi permanent color to cover grey completely or grey blending.  It can also change your hair color to cooler, warmer or darker.   Natural blow dry is included in the service, if you need a special styling or changing your natural texture please add the blow dry service."
        },
        {
        id: '1',
        title: "Color and Haircut",
        price: "240",
        details: "Using either permanent or semi permanent color to cover up grey or grey blending.  It can also change your hair color to cooler, warmer or darker.  Plus customized haircut and blow dry."
        },
        {
        id: '2',
        title: "Color and Partial Highlight",
        price: "220 & up",
        details: "Adding highlights during the color service to create dimension and sparkles.  Natural blow dry is included in the service, if you need special styling or changing your natural texture, please add the blow dry service."
        },
        {
        id: '3',
        title: "Color, Partial Highlight, Haircut and Blow-Dry",
        price: "330 & up",
        details: "Using either permanent or semi permanent color to cover up grey or grey blending.  It can also change your hair color to cooler, warmer or darker.  Adding highlights to your desired color to create dimensions and sparkles.  Plus customized haircut and blow dry."
        }
      ]
    }
    const subTitleData = {
      title: 'COLOR',
      subtitle: 'TO DAZZLE',
      description: "HAVE THE COLOR YOU'VE ALWAYS WANTED",
      imgUrl: '/images/services/color_main.jpg'
    }
    return (
      <div>

        <SubTitle data={subTitleData}/>

        <Collections data={serviceData}/>

        <Contact />

      </div>
    )
  }
}

export default Color;
