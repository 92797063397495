import React, { Component } from 'react';

import Collections from '../body/Collections';
import Contact from '../body/Contact';
import SubTitle from '../body/SubTitle';

class Haircut extends Component {
  render() {
    const serviceData = {
      imgurl: '/images/services/cut.jpg',
      smallTitle: 'Hair by Julie',
      title: 'Cuts & Treatments',
      data: [
        {
        id: '0',
        title: 'Bang Trim',
        price: '10',
        details: "Don't take the scissor to your hair, allow me to maintain your bang in between haircuts."
        },
        {
        id: '1',
        title: "Women's Blow-Dry",
        price: '50',
        details: "Professional styling and finishing service."
        },
        {
        id: '2',
        title: "Women's Haircut",
        price: '130',
        details: "A customized haircut designed by your stylist to fit your individual style and preferences"
        },
        {
        id: '3',
        title: "Men's Haircut",
        price: '110',
        details: "A customized haircut designed by your stylist to fit your individual style and preferences."
        },
        {
        id: '4',
        title: "Conditioning Treatment",
        price: '60',
        details: "A formula that is designed to restore proteins and moisture in the hair."
        },
        {
        id: '5',
        title: "Protein Conditioning Treatment",
        price: '80',
        details: "It's design for overly process hair, especially blonde. It restore the protein after highlighting process."
        },
        {
        id: '6',
        title: "Relaxer - Keratin Treatment",
        price: '300  & up',
        details: "Blowout that conditions, relaxes and eliminates frizz from hair for up-to four month."
        },
        {
        id: '7',
        title: "Complimentary consultation",
        price: '0',
        details: "Any questions about your hair or just wanted to interview me before hand, please come in and have a chat with me."
        }
      ]
    }
    const subTitleData = {
      title: 'CUTS & TREATMENTS',
      subtitle: 'TO IMPRESS',
      description: "make you look and feel your personal best",
      imgUrl: '/images/services/cut_main.jpg'
    }
    return (
      <div>

        <SubTitle data={subTitleData}/>

        <Collections data={serviceData}/>

        <Contact />

      </div>
    )
  }
}

export default Haircut;
