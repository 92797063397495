import React, { Component } from 'react';

import { Segment, Container, Grid, List, Image} from 'semantic-ui-react';

class Footer extends Component {
  render() {
    return (
      <Segment inverted vertical style={{ padding: '5em 0em' }}>
        <Container>
          <Grid inverted textAlign='center'>
            <Grid.Row>
              <Grid.Column>
                <Image src='/images/julie_logo_white.png' size='tiny' centered/>
                <br/>

                {/* contct info */}
                <List link inverted>
                  <List.Item as="a">
                    Hours of Operation
                  </List.Item>
                  <List.Item as="a">
                    TUE,  FRI,  SUN:  10:30AM - 7:30PM
                  </List.Item>
                  <br/>
                  <List.Item as='a' href="https://www.google.com/maps/place/124+W+24th+St,+New+York,+NY+10011/@40.7439229,-73.9956915,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259a4dbfbdd19:0x61b74effd28471bf!8m2!3d40.7439229!4d-73.9937052" target='new'>
                    <List.Icon name='map marker alternate' color='grey' size='large' style={{paddingRight: '30px'}}/>
                    124 West 24th street Suite 16, New York, NY 10011
                  </List.Item>
                  <br/>
                  <List.Item style={{margin: '20px, 0px'}} as='a' href="tel:917-386-5485">
                    <List.Icon name='phone' color='grey' size='large' style={{paddingRight: '30px'}} />
                    (917) 386 - 5485
                  </List.Item>
                  <br/>
                  <List.Item as='a' href="mailto:hairbyjulielin@gmail.com?subject=Mail%20From%20Our%20Website">
                    <List.Icon name='mail' color='grey' size='large' style={{paddingRight: '30px'}} />
                    HairByJulieLin@gmail.com
                  </List.Item>
                </List>
                <br/>

                {/* Social Medias */}
                <List animated horizontal relaxed='very'>
                  <List.Item>
                    <List.Content>
                      <List.Header as='a' href='https://www.facebook.com/Hairbyjulielin/' target='new'>
                        <List.Icon name='facebook official' color='grey' size='big'/>
                      </List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header as='a' href='https://www.instagram.com/hairbyjulielin/' target='new'>
                        <List.Icon name='instagram' color='grey' size='big'/>
                      </List.Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <p style={{textAlign: 'center', paddingTop: '50px', color: 'grey'}}>© Copyright Hair by Julie 2022 | All Rights Reserved</p>
        </Container>
      </Segment>
    )
  }
}

export default Footer;
