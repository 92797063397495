import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Header from './Header';
import Footer from './Footer';
import Landing from './Landing';
import Haircuts from './services/Haircuts';
import Color from './services/Color';
import Highlight from './services/Highlight';


class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <ScrollToTop>
              <Header />
              <Route exact path="/" component={Landing} />
              <Route path="/services/cuts" component={Haircuts} />
              <Route path="/services/color" component={Color} />
              <Route path="/services/highlight" component={Highlight} />
              <Footer />
            </ScrollToTop>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
