import React, { Component } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

class Testimonial extends Component {

  render() {
    const content = [
      {
        image: '/images/carousels/cut.jpg',
        name: 'Lily L. "Yelp"',
        description: "Julie is a true New York treasure..."
      },
      {
        image: '/images/carousels/color.jpg',
        name: 'Kelly S. "Vagaro"',
        description: "Julie is a master at what she does..."
      },
      {
        image: '/images/carousels/highlight.jpg',
        name: 'Mackenzie R. "Vagaro"',
        description: "Julie Lin is a master of hair color..."
      }
    ]

    return (
      <Slider autoplay={3000}>
      	{content.map((item, index) => (
      		<div
      			key={index}
      			style={{
              background: `url('/images/background.jpg')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              opacity: ".7"
            }}
      		>
            <Container style={{textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
              <Fade bottom duration={2000} cascade>
                <Container style={{color:'white', letterSpacing: '3px', textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
                  <h4 style={{paddingBottom: '25px'}}>
                  GUEST TESTIMONIALS
                  </h4>
                  <Divider style={{margin: '0% 40%', borderTop: '1px solid white', borderBottom: '1px solid white'}}/>
                  <p style={{color: 'white', fontSize: "1.75em", paddingTop: '25px'}}>{item.description}</p>
                  <h1 style={{color: 'white', fontSize: "1.25em", wordSpacing: "5px", letterSpacing: "2px"}}>
                  - {item.name} -
                  </h1>
                </Container>
              </Fade>
            </Container>
      		</div>
      	))}
      </Slider>
    )
  }
}

export default Testimonial;
