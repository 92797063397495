import React, { Component } from 'react';

import ImageWithText from './body/ImageWithText';
// import DoubleImageWithText from './body/DoubleImageWithText';
import Contact from './body/Contact';
import Testimonial from './body/Testimonial';
import Instagram from './body/Instagram';


class Landing extends Component {
  render() {
    return (
      <div>

        <ImageWithText />

        <Testimonial />

        <Instagram />

        <Contact />

      </div>
    )
  }
}

export default Landing;
