import React, { Component } from 'react';
import StickyBar from './navbar/StickyBar';

class Header extends Component {

  render() {

    return(
      <div>
        <StickyBar />
      </div>
    );
  }
}

export default Header;
