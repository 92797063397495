import React, { Component } from 'react';

import Collections from '../body/Collections';
import Contact from '../body/Contact';
import SubTitle from '../body/SubTitle';

// {
//   id: '4',
//   title: "Highlight",
//   price: "180 & up",
//   details: "A highlight creates a contrast and brightening effect throughout hair."
// },
// {
//   id: '5',
//   title: "Highlight, Haircut and Blow-Dry",
//   price: "290 & up",
//   details: "A highlight creates a contrast and brightening effect throughout hair plus haircut and professional finish."
// },

class Highlights extends Component {
  render() {
    const serviceData = {
      imgurl: '/images/services/highlight.jpg',
      smallTitle: 'Hair by Julie',
      title: 'Highlight',
      data: [
        {
          id: '0',
          title: "Half Head Highlights",
          price: "190 & up",
          details: "Highlights is to create contrast and brightness through out the hairline, side and top of your head by using liter color or bleach.  Different techniques can be apply to achieve the desired style.   Natural blow dry is included in the service, if you fancy a special styling or changing your natural texture, please add blow dry service."
        },
        {
          id: '1',
          title: "Half Head Highlights with Haircut",
          price: "320 & up",
          details: "Highlights is to create contrast and brightness through out the hairline, side and top of your head by using liter color or bleach.  Different techniques can be apply to achieve the desired style.  Plus customized haircut and blow dry."
        },
        {
          id: '2',
          title: "Whole Head Highlights",
          price: "220 & up",
          details: "Whole head of Highlights is to create contrast and brightness through out your entire head by using liter color or bleach.  Different techniques can be apply to achieve the desired style.   Natural blow dry is included in the service, if you fancy a special styling or changing your natural texture, please add blow dry service."
        },
        {
          id: '3',
          title: "Whole Head Highlights with Haircut",
          price: "340 & up",
          details: "Whole head of Highlights is to create contrast and brightness through out your entire head by using liter color or bleach.  Different techniques can be apply to achieve the desired style.  Plus customized haircut and blow dry."
        },

        {
          id: '4',
          title: "Ombré and haircut",
          price: "330 & up",
          details: "Using special coloring technique to make the end of the hair either lighter or different shade of color from the root plus haircut and professional finish."
        },
        {
          id: '5',
          title: "Ombré",
          price: "220 & up",
          details: "Using special coloring technique to make the end of the hair either lighter or different shade of color from the root."
        },
        {
          id: '6',
          title: "Toner / Gloss",
          price: "60 & up",
          details: "Toner helps make the final result of the color after the highlights or ombré service to be warmer or cooler in tone, and correcting unwanted shades. It help to get an even hair colour result and shine from root to end."
        }
      ]
    }
    const subTitleData = {
      title: 'HIGHLIGHT',
      subtitle: 'to spark',
      description: "bring back that multi-hued look that you were born with",
      imgUrl: '/images/services/highlight_main.jpg'
    }
    return (
      <div>

        <SubTitle data={subTitleData}/>

        <Collections data={serviceData}/>

        <Contact />

      </div>
    )
  }
}

export default Highlights;
