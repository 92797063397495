import axios from 'axios';
import { FETCH_INSTAGRAM } from './types';


export const fetchInstagram = () => async dispatch => {

  const res = await axios.get('/api/instagram/feeds');

  // console.log("inside fetchInstagram", res.data)

  dispatch({ type: FETCH_INSTAGRAM, payload: res.data });
}
