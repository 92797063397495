import React, { Component } from 'react';
import { Container, Divider} from 'semantic-ui-react';


class SubTitle extends Component {
  render(props) {
    const { title, description, subtitle, imgUrl } = this.props.data

    return (
      <div style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '700px',
        backgroundPosition: 'center top'
      }}>
        <Container style={{color:'white', letterSpacing: '3px', textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
          <h2 style={{fontSize: "3.5em", textTransform: 'uppercase'}}>
          { title }
          </h2>
          <h2 style={{fontSize: "1.5em", paddingBottom: '25px', textTransform: 'uppercase'}}>
          { subtitle }
          </h2>
          <Divider style={{margin: '0% 40%', borderTop: '1px solid white', borderBottom: '1px solid white'}}/>
          <h2 style={{paddingTop: '25px', fontSize: "3.5em", textTransform: 'uppercase'}}>
            { description }
          </h2>
        </Container>
      </div>
    )
  }
}

export default SubTitle;
