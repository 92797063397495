import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Divider, Header, Button, Grid, Image } from 'semantic-ui-react';

const contents = {
  title: "Meet",
  header: "Julie Lin",
  details: "I have been working in the beauty industry for over 20 years, during which I have worked on photo shoots, fashion shows, hair shows, movie sets, even owned a hair salon. All those years of experience helped me grow as a hairstylist and made me realize that my true passion is working behind a chair and taking care of my clients. Every single person that sits on my styling chair deserves my full attention, my best quality of work and service because I truly care!",
  detailsTwo: "Yves Saint Laurent: 'Fashions fade, style is eternal.' is my hair philosophy. My style of cutting is to enhance one's natural texture whether it's curly, straight or everything in between. I design hair to compliment clients facial features and their lifestyle. I educate my clients on how to take care of their hair at home, so you always have GOOD HAIR DAY.",
  detailsThree: "I'm always driving to be better than I'm today! Please write a review and send me feedback, it will be greatly appreciated.",
  imgUrl: 'images/julie.jpg',
  bttUrl: '/services',
  button: 'SEE DETAILS'
}

class ImageWithText extends Component {

  render() {
    const { title, header, details, detailsTwo, detailsThree, imgUrl} = contents;

    return(
      <div style={{padding: "50px 0px"}}>
        <Grid stackable as={Container} divided='vertically' style={{verticalAlign: 'middle'}}>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign='middle'>
              <Header as='h5' textAlign='left' style={{padding: '10px 0px'}}>
                {title}
              </Header>
              <Divider style={{width: '100px'}} />
              <Header style={{padding: '10px 0px', fontStyle: 'italic'}} as='h2'>
                {header}
              </Header>
              <Container textAlign='justified' style={{padding: '20px 0px', hyphens: 'none', WebkitHyphens: 'none', MozHyphens: 'none'}}>
                <p >{details}</p>
                <p>{detailsTwo}</p>
                <p>{detailsThree}</p>

              </Container>
              <Fade left duration={1500}>
                <Button target="_blank" as='a' href="https://squareup.com/appointments/book/3529T73ZXBZEZ/hair-by-julie-lin-new-york-ny" style={{margin: '25px 0px', borderRadius: '30px', fontSize: '1.3em', letterSpacing: '2px'}} basic color='teal'>
                Book An Appointment
                </Button>
              </Fade>
            </Grid.Column>
            <Grid.Column>
              <Image src={imgUrl} fluid/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default ImageWithText;
