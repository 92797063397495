import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Header, Image, Grid, Card, Icon } from 'semantic-ui-react'
import { fetchInstagram } from '../../actions';

class Instagram extends Component {
  async componentWillMount() {
    // console.log('inside componentWillMount')
    await this.props.fetchInstagram();
    // console.log('this.props.instagram.data', this.props.instagram.data)

    this.setState({ instagramData: this.props.instagram.data})
  }

  state={
    instagramData: []
  }

  renderInstagram(props) {
    const size = 12;

    const instagramRenderedData = this.state.instagramData.slice(0, size).map((e) => {
      return (
        <Grid.Column key={e.id} style={{padding: "1px 1px"}}>
          <Card as='a' href={e.link} target="_blank">
            <div>
              <Image src={e.images.low_resolution.url} />
            </div>
           <Card.Content extra>
             <Grid>
              <Grid.Column floated='left' width={7}>
                <div>
                  <Icon name='heart' />
                  {e.likes.count}
                </div>
              </Grid.Column>
              <Grid.Column floated='right' width={7}>
                <div>
                  <Icon name='chat' />
                  {e.comments.count}
                </div>
              </Grid.Column>
            </Grid>
           </Card.Content>
          </Card>
        </Grid.Column>
      )
    })

    return instagramRenderedData
  }

  render() {

    return (
      <Container style={{ padding: "50px 0px"}}>
        <Header as='h2' textAlign='center' style={{ paddingBottom: "25px"}}>
          Follow me
          <Header as="a" href="https://www.instagram.com/hairbyjulielin/"> @HairByJulieLin </Header>
        </Header>

        <Grid doubling columns={4}>
          {this.renderInstagram()}
        </Grid>

      </Container>
    )
  }
}

function mapStateToProps({ instagram }) {
  return { instagram };
}

export default connect(mapStateToProps, {fetchInstagram})(Instagram);
